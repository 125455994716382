import React, { useEffect, useState } from 'react';
import useServiceAuth from 'auth/useServiceAuth';
import { fetchDplAllCourses } from 'apis/dplAPIs';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'components/Loading';
import {
  faMagnifyingGlass,
  faRefresh,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { createTerm } from 'utils';
import { checksheetGet } from 'apis/checksheetAPIs';

const ChecksheetCatalogChanges = () => {
  const { getAccessToken } = useServiceAuth();

  const params = new URLSearchParams(window.location.search);
  const checkId = params.get('id');

  // Extract the first 4 characters as the year
  const year = checkId && checkId.length >= 4 ? checkId.substring(0, 4) : null;
  const nextYear = year ? parseInt(year) + 1 : null;

  let term = '2257';

  if (year) {
    term = createTerm(year);
  }

  // const [error, setError] = useState('');
  const [allTableData, setAllTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const {
    data: checksheetData,
    // error: getChecksheetError,
    // isError: getChecksheetIsError,
    isSuccess: getChecksheetIsSuccess,
  } = useQuery({
    queryKey: ['checksheet', checkId],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetGet({ id: checkId, token });
    },
    enabled: !!checkId,
  });

  const {
    data: fetchCoursesData,
    // error: fetchProgramsError,
    // isError: fetchProgramsIsError,
    isSuccess: fetchCoursesIsSuccess,
    isPending: fetchCoursesIsLoading,
    // fetchStatus: fetchProgramsFetchStatus,
  } = useQuery({
    queryKey: ['courses', term],
    queryFn: () => {
      return fetchDplAllCourses(term);
    },
  });

  /**
   * Merges componentObjects with componentRequirements by iterating through componentObjects keys.
   *
   * @param {Object} json - The main data object containing details, componentObjects, and componentRequirements.
   * @returns {Object} The updated JSON object with merged sections.
   */
  function mergeComponents(json) {
    const { componentObjects, componentRequirements } = json.details;

    // Initialize the object to store merged components
    const mergedComponents = {};

    // Iterate through componentObjects
    for (const [key, componentObject] of Object.entries(componentObjects)) {
      // Start with details from componentObjects
      const mergedComponent = {
        year: componentObject.details.year,
        componentName: componentObject.details.componentName,
        notes: componentObject.details.notes || '',
        settings: componentObject.details.settings || {},
        subsections: { ...componentObject.details.subsections }, // Include subsections from componentObjects
      };

      // Check if matching key exists in componentRequirements
      if (componentRequirements[key]?.subsections) {
        // Merge subsections from componentRequirements
        Object.entries(componentRequirements[key].subsections).forEach(
          ([subKey, subSection]) => {
            if (mergedComponent.subsections[subKey]) {
              // Append requirements if the subsection already exists
              mergedComponent.subsections[subKey].requirements.push(
                ...subSection.requirements
              );
            } else {
              // Add subsection if it doesn't exist
              mergedComponent.subsections[subKey] = subSection;
            }
          }
        );
      }

      // Add the merged component to the mergedComponents object
      mergedComponents[key] = mergedComponent;
    }

    // Assign mergedComponents to a new 'sections' property in the original JSON
    json.details.sections = mergedComponents;

    return json;
  }

  /**
   * Transforms the checksheetData json to include merged componentObjects and componentRequirements.
   *
   * @param {Object} data - The input data object containing templateObject and checksheet details.
   * @returns {Object} The transformed data object with merged sections.
   */
  function createChecksheetNewJson(data) {
    // Validate the input structure
    if (
      !data ||
      !data.templateObject ||
      !data.templateObject.componentObjects
    ) {
      console.error(
        'Invalid data structure: templateObject or its componentObjects are missing.'
      );
      return data;
    }

    // Step 1: Move componentObjects from templateObject to details
    const componentObjects = data.templateObject.componentObjects;

    // Ensure details is an object
    data.details = JSON.parse(data['details']);

    // Move componentObjects to details and remove from templateObject
    if (componentObjects) {
      data.details.componentObjects = componentObjects;
      delete data.templateObject.componentObjects;
    }

    console.log('data.details:', data.details);

    // Step 2: Parse details for each componentObject to ensure it is an object
    if (data.details.componentObjects) {
      const componentObjects = data.details.componentObjects;

      // Parse the details property for each componentObject
      Object.keys(componentObjects).forEach((key) => {
        componentObjects[key].details = JSON.parse(
          componentObjects[key].details
        );
      });
    }

    // Step 3: Merge componentObjects with componentRequirements
    mergeComponents(data);

    // Step 4: Cleanup: Remove componentRequirements and componentObjects after merging
    if (data.details.componentRequirements) {
      delete data.details.componentRequirements;
    }
    if (data.details.componentObjects) {
      delete data.details.componentObjects;
    }

    return data;
  }

  /**
   * Compares the current courses with the DPL courses and identifies discrepancies.
   * If differences exist, it merges the data and adds the merged object to the report.
   *
   * @param {Array} currentCourses - Array of current courses (checksheet data).
   * @param {Array} dplCourses - Array of DPL courses (external data).
   * @returns {Array} Array of merged courses with discrepancies highlighted.
   */

  const mergeCourseData = (currentCourses, dplCourses) => {
    if (!Array.isArray(currentCourses) || !Array.isArray(dplCourses)) {
      console.error('Invalid input: Both parameters should be arrays.');
      return [];
    }

    return (
      currentCourses
        .map((currentCourse) => {
          const match = (() => {
            let partialMatch = null;

            // Find the best match
            for (const dplCourse of dplCourses) {
              if (currentCourse.checksheetCourseId) {
                // Check for a full match
                if (
                  dplCourse.courseId === currentCourse.checksheetCourseId &&
                  dplCourse.subject === currentCourse.checksheetCourseSubject &&
                  dplCourse.catalogNumber ===
                    currentCourse.checksheetCourseCatalogNumber
                ) {
                  // console.log('Full match found:', dplCourse);
                  return dplCourse; // Return immediately if a full match is found
                }

                // Save partial match if only courseId matches
                if (
                  !partialMatch &&
                  dplCourse.courseId === currentCourse.checksheetCourseId
                ) {
                  console.log('Partial match found:', dplCourse);
                  partialMatch = dplCourse;
                }
              } else {
                // If checksheetCourseId is not provided, match based on subject and catalogNumber
                if (
                  dplCourse.subject === currentCourse.checksheetCourseSubject &&
                  dplCourse.catalogNumber ===
                    currentCourse.checksheetCourseCatalogNumber
                ) {
                  return dplCourse; // Return immediately if a match is found
                }
              }
            }

            // Return the partial match if no full match was found
            return partialMatch;
          })();
          // console.log('match:', match);

          if (match) {
            const {
              description,
              subject,
              catalogNumber,
              courseId,
              requirementDesignationDescription,
            } = match;
            const {
              checksheetCourseDescription,
              checksheetCourseSubject,
              checksheetCourseCatalogNumber,
              checksheetCourseGs,
            } = currentCourse;

            // Check and merge differences
            if (
              description !== checksheetCourseDescription ||
              subject !== checksheetCourseSubject ||
              catalogNumber !== checksheetCourseCatalogNumber ||
              (requirementDesignationDescription &&
                !requirementDesignationDescription.includes(
                  checksheetCourseGs
                )) ||
              (requirementDesignationDescription &&
                requirementDesignationDescription.split(' ')[0].length === 4 &&
                !checksheetCourseGs)
            ) {
              return {
                ...currentCourse,
                description,
                subject,
                catalogNumber,
                courseId,
                requirementDesignationDescription,
              };
            }
          } else {
            return currentCourse;
          }

          // Return null if no match or descriptions are the same
          return null;
        })
        // Filter out null values
        .filter((course) => course !== null)
    );
  };

  const getTableData = (data) => {
    // Remove unwanted fields and create a cleaned array
    const cleanedData = data.map(
      ({ subsectionName, sectionName, courseListName, ...rest }) => rest
    );

    // Use a Set to remove duplicates based on a JSON stringified version of each object
    const uniqueData = Array.from(
      new Set(cleanedData.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item));

    return uniqueData;
  };

  const handleFindCourse = (subject, catalogNumber, index) => {
    console.log('subject:', subject);
    console.log('catalogNumber:', catalogNumber);
    const filtered = allTableData.filter(
      (course) =>
        course.checksheetCourseSubject === subject &&
        course.checksheetCourseCatalogNumber === catalogNumber
    );
    console.log('filtered:', filtered);
    setFilteredCourses(filtered);
    setSelectedRowIndex(index); // Track the clicked row
  };

  useEffect(() => {
    if (fetchCoursesIsSuccess) {
      if (checkId !== '' && checkId !== null) {
        // if (getChecksheetIsError) setError(getChecksheetError.message);
        console.log('getChecksheetIsSuccess: ', getChecksheetIsSuccess);
        if (getChecksheetIsSuccess) {
          console.log('checksheetData: ', checksheetData);

          //create the new json for the checksheet with all the requirements from the template
          const newJson = createChecksheetNewJson(checksheetData);
          console.log('newJson:', JSON.stringify(newJson, null, 2));

          const jsonDetails = newJson.details;
          console.log('jsonDetails:', jsonDetails.sections);

          // get all course requirements from the checksheet
          const requirements = jsonDetails.sections;
          const courseLists = jsonDetails.courseLists;

          let checksheetCourses = [];

          if (requirements) {
            Object.values(requirements).forEach((section) => {
              Object.values(section.subsections).forEach((subsection) => {
                subsection.requirements.forEach((requirement) => {
                  requirement.courses.forEach((course) => {
                    if (course.recommended?.courses) {
                      course.recommended.courses.forEach(
                        (recommendedCourse) => {
                          if (
                            recommendedCourse.subject &&
                            recommendedCourse.number
                          ) {
                            checksheetCourses.push({
                              checksheetCourseDescription:
                                recommendedCourse.description,
                              checksheetCourseId: recommendedCourse.courseId,
                              checksheetCourseSubject:
                                recommendedCourse.subject,
                              checksheetCourseCatalogNumber:
                                recommendedCourse.number,
                              checksheetCourseGs: recommendedCourse.gs,
                              checksheetCourseProposedTitle:
                                recommendedCourse.proposedTitle || '',
                              subsectionName: subsection.subsectionName || '',
                              sectionName: section.componentName || '',
                            });
                          }
                        }
                      );
                    } else {
                      if (course.subject && course.number) {
                        checksheetCourses.push({
                          checksheetCourseDescription: course.description,
                          checksheetCourseId: course.courseId,
                          checksheetCourseSubject: course.subject,
                          checksheetCourseCatalogNumber: course.number,
                          checksheetCourseGs: course.gs,
                          checksheetCourseProposedTitle:
                            course.proposedTitle || '',
                          subsectionName: subsection.subsectionName || '',
                          sectionName: section.componentName || '',
                        });
                      }
                    }
                  });
                });
              });
            });
          }

          if (courseLists) {
            Object.values(courseLists).forEach((courseList) => {
              // console.log(
              //   `Processing Course List: ${courseList.courseListName}`
              // );

              // Iterate through each course in the course list
              courseList.courses.forEach((course) => {
                // console.log(
                //   `Processing Course Requirement: ${course.requirementId}`
                // );

                // Iterate over each course detail in the 'courses' array
                course.courses.forEach((course) => {
                  if (course.recommended?.courses) {
                    course.recommended.courses.forEach((recommendedCourse) => {
                      if (
                        recommendedCourse.subject &&
                        recommendedCourse.number
                      ) {
                        checksheetCourses.push({
                          checksheetCourseDescription:
                            recommendedCourse.description,
                          checksheetCourseId: recommendedCourse.courseId,
                          checksheetCourseSubject: recommendedCourse.subject,
                          checksheetCourseCatalogNumber:
                            recommendedCourse.number,
                          checksheetCourseGs: recommendedCourse.gs,
                          checksheetCourseProposedTitle:
                            recommendedCourse.proposedTitle || '',
                          courseListName: courseList.courseListName,
                        });
                      }
                    });
                  } else {
                    if (course.subject && course.number) {
                      checksheetCourses.push({
                        checksheetCourseDescription: course.description,
                        checksheetCourseId: course.courseId,
                        checksheetCourseSubject: course.subject,
                        checksheetCourseCatalogNumber: course.number,
                        checksheetCourseGs: course.gs,
                        checksheetCourseProposedTitle:
                          course.proposedTitle || '',
                        courseListName: courseList.courseListName,
                      });
                    }
                  }
                });
              });
            });
          }

          const result = mergeCourseData(checksheetCourses, fetchCoursesData);
          console.log('result:', result);
          setAllTableData(result);
          const result2 = getTableData(result);
          console.log('result2:', result2);
          setTableData(result2);

          // console.log('tableData:', checksheetCourses);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    getChecksheetIsSuccess,
    checksheetData,
    checkId,
    fetchCoursesData,
    fetchCoursesIsSuccess,
  ]);

  if (fetchCoursesIsLoading) return <Loading />;

  return (
    <div className="container">
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h3>
            {year} - {nextYear} Course Catalog Changes
          </h3>
          <div
            className="refresh-container py-2"
            style={{ cursor: 'pointer', color: 'maroon' }}
            onClick={() => window.location.reload()}
            role="button"
            tabIndex="0"
            aria-label="Refresh the course catalog changes"
          >
            <FontAwesomeIcon icon={faRefresh} /> <span>Refresh</span>
          </div>
        </div>

        <div className="d-flex">
          <div style={{ width: '50%' }}>
            <h4>Previous courses</h4>
          </div>
          <div style={{ width: '50%' }}>
            <h4>Changes</h4>
          </div>
        </div>

        <div className="uds-table" style={{ width: '100%' }}>
          <table
            className="checksheetCatalogChange"
            aria-label="Course Catalog Changes"
          >
            <thead>
              <tr>
                <th>Subject</th>
                <th>Number</th>
                <th>Title</th>
                <th>General Studies</th>
                <th className="border-left">Subject</th>
                <th>Number</th>
                <th>Title</th>
                <th>General Studies</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  className={
                    selectedRowIndex === index ? 'highlighted-row' : ''
                  }
                >
                  <td>{row.checksheetCourseSubject}</td>
                  <td>{row.checksheetCourseCatalogNumber}</td>
                  <td>
                    {row.checksheetCourseProposedTitle &&
                    row.checksheetCourseProposedTitle.trim() !== ''
                      ? row.checksheetCourseProposedTitle
                      : row.checksheetCourseDescription}
                  </td>
                  <td>{row.checksheetCourseGs}</td>

                  {row.subject &&
                  row.catalogNumber &&
                  row.description &&
                  row.description.trim() !== '' ? (
                    <>
                      <td className="border-left">{row.subject}</td>
                      <td>{row.catalogNumber}</td>
                      <td>{row.description}</td>
                    </>
                  ) : (
                    <td colSpan="3" className="border-left">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ color: 'maroon', marginRight: '8px' }}
                      />{' '}
                      {`${row.subject || ''} ${row.catalogNumber || ''} ${row.description || 'course not found'}`}
                    </td>
                  )}

                  <td>
                    {row.requirementDesignationDescription?.split(' ')[0]
                      ?.length === 4
                      ? row.requirementDesignationDescription.split(' ')[0]
                      : ''}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{ cursor: 'pointer', color: 'maroon' }}
                      title="Find in checksheet"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFindCourse(
                          row.checksheetCourseSubject,
                          row.checksheetCourseCatalogNumber,
                          index
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="py-3">
        {filteredCourses.length > 0 &&
          filteredCourses.map((course, index) => (
            <div key={index}>
              {index === 0 && (
                <>
                  <div className="fw-bold">
                    {course.checksheetCourseSubject}{' '}
                    {course.checksheetCourseCatalogNumber}
                  </div>
                  <div className="fw-bold">
                    <span style={{ backgroundColor: 'gold' }}>
                      {filteredCourses.length} instances found
                    </span>
                  </div>
                </>
              )}

              <li>
                {course.sectionName && <strong>Section: </strong>}
                {course.sectionName && `${course.sectionName}`}
                {course.subsectionName && course.sectionName && ' / '}
                {course.subsectionName && <strong>Subsection: </strong>}
                {course.subsectionName && `${course.subsectionName}`}
                {course.courseListName && <strong>Course List: </strong>}
                {course.courseListName && `${course.courseListName}`}
              </li>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChecksheetCatalogChanges;
